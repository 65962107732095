/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */

#root {
  height: 100vh;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  /* gap: 1em; */
  font-family: sans-serif;
  color: #333;
  /* background-color: #f3e4d7; */
}

/* height of the container */
.leaflet-container {
  height: 100vh;
  width: 100%;
  /* border-radius: 2rem; */
}

.cluster-icon {
  background-color: #333;
  height: 2em;
  width: 2em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.2rem;
  box-shadow: 0 0 0px 5px #fff;
}

.menu3 li:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  display: block;
  transition: all 0.3s ease;
  bottom: 25%;
}
.menu3 li:hover::after {
  width: 100%;
  height: 1px;
  background-color: #fff;
}

.menu3 li {
  /* display: block; */
  position: relative;
  /* display: flex; */
  /* align-items: center; */
  /* color: #fff; */
  /* text-transform: uppercase; */
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
